import React from "react"
import {Link} from "gatsby"
import logo_cmm from "../../images/logo-cmm.svg"
import "./styles.css"
import table_chart from "../../images/graphs/desktop/savings_chart_updated.png"
import table_chart_mobile from "../../images/graphs/mobile/3.3_table_mobile.png"
import websiteIcon from "../../images/3.3/website_icons_v2-04.png"
import percent80 from "../../images/3.3/80percent.png"
import oneGramPill from "../../images/3.3/Vascepa_onegram_Bottle_Pill.jpg"
import flashcardOne from "../../images/3.3/flashcard_1.jpg"
import flashcardTwo from "../../images/3.3/flashcard_2.jpg"
import retail from "../../images/3.3/retail-fs.jpg"
import stepGettingVascepa from "../../images/3.3/step-getting-vascepa.png"
import flashcardThree from "../../images/3.3/icd-coding.jpg"
import flashcardFour from "../../images/3.3/flashcard_4.jpg"
import flashcardFive from "../../images/3.3/daw_req.png"
import TexasPALetter from "../../images/3.3/texas_pa_letter.jpg"
import DAWCode from "../../images/3.3/DAWCode.png"

import Magnify_icon from "../../images/magnify-icon.svg"
import MagentaBrackets from "../../images/magenta-brackets.png"

export default props => {
  const supStyle = {
    top: '-1em'
  };


  function goModal(evt) {
    const dataAtt = evt.target.dataset.modal;
    //console.log(dataAtt)
    props.goModal(dataAtt);
  };

  function goModalTwo(evt) {
    const dataAtt = evt.target.dataset.modal;
    const dataHref = evt.target.dataset.href || null;
    props.goModalTwo(dataAtt, dataHref);
  };

  function noScroll(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    return false;
  }

  return (
    <main className="center-grid mobile-m-b-20">
      <section id="section2" className="endpoints">
        <h2 className="cv-headline sm-mt-30">
          Branded VASCEPA is{" "}
          <strong>covered by major national PBMs and health plans:</strong>
        </h2>

        <div className="icon-lockup access-icon-lock div-flex">
          <div className="icon-container">
            {/* <img className="sub-icon" src={percent80} /> */}
            <img className="sub-icon bottle" src={oneGramPill} alt="Vascepa bottle and capsule" />
          </div>
          <div>
            <p className="m-t-0">Branded VASCEPA is <strong>covered by major national PBMs and health plans</strong>:</p>
            <ul className="content-list m-b-30">
              <li className="primary-list-item">With a generic copay</li>
              <li className="primary-list-item">
                With no prior authorizations or other restrictions
              </li>
              <li className="primary-list-item">
                For ~40 million lives in the Commercial business segment
                <sup>&dagger;</sup>
              </li>
            </ul>
            <div className="access-callout mobile-m-b-35">
              <div className="cta-container m-center fit-content">
                <h3 className="cta-copy">
                  See if your patients have unrestricted access to VASCEPA
                </h3>
                <a
                  href="https://amarincorp.com/docs/Formulary_Status.pdf"
                  target="_blank"
                  className="cta-btn"
                >
                  See plans
                </a>
              </div>
            </div>

          </div>
        </div>
        <div className="dosing-callout"></div>
      </section>

      <hr className="m-b-70" />
      <section id="section2" className="endpoints">
        <h2 className="cv-headline">
          <strong>VASCEPA is the exclusive formulary product</strong> for many
          commercial and Medicare Part D patients<sup>&dagger;</sup>
        </h2>

        <div className="icon-lockup access-icon-lock div-flex">
          <div>
            <ul className="content-list m-b-30">
              <li className="primary-list-item">
                VASCEPA is covered without restrictions for &gt;90% of Medicare
                Part D patients<sup>&Dagger;</sup>
              </li>
              <li className="primary-list-item">
                Approximately 60% of Medicare Part D patients have no coverage
                for generic icosapent ethyl<sup>&dagger;</sup>
              </li>
            </ul>
          </div>
        </div>
        <div className="dosing-callout"></div>
      </section>
      <hr />

      <section name="section3" className="endpoints">
        <h2 className="cv-headline">
          A range of <strong>access resources</strong>
        </h2>
        <div className="flashCard-container">
          <div className="item1">
            <div className="flashCard">
              <div className="flashCard-l" style={{ position: "relative" }}>
                <img src={flashcardTwo} alt="Formulary Status for Select Plans"></img>
              </div>
              <div className="flashCard-r1">
                Formulary Status for Select Plans
              </div>
              <div className="flashCard-r2">
                <a
                  href="https://amarincorp.com/docs/Formulary_Status.pdf"
                  target="_blank"
                  className="cta-btn"
                >
                  Download
                </a>
              </div>
            </div>
          </div>
          <div className="item2">
            <div className="flashCard">
              <div className="flashCard-l">
                <img src={flashcardFive} alt="State DAW Requirements"></img>
                {/* <div style={{width: "132px", height: "170px", border: "solid 2px magenta"}}>
                    <h2 style={{color: "magenta"}}>FPO</h2>
                  </div> */}
              </div>
              <div className="flashCard-r1">State DAW Requirements</div>
              <div className="flashCard-r2">
                <a
                  target="_blank"
                  href="https://amarincorp.com/docs/State_DAW_Requirements.pdf"
                  className="cta-btn"
                >
                  Download
                </a>
              </div>
            </div>
          </div>
          <div className="item3">
            <div className="flashCard">
              <div className="flashCard-l">
                <img src={flashcardThree} alt="ICD-10 Coding Flashcard"></img>
              </div>
              <div className="flashCard-r1">ICD-10 Coding Flashcard</div>
              <div className="flashCard-r2">
                <a
                  target="_blank"
                  href="https://amarincorp.com/docs/Coding_Flashcard.pdf"
                  className="cta-btn"
                >
                  Download
                </a>
              </div>
            </div>
          </div>
          <div className="item4">
            <div className="flashCard">
              <div className="flashCard-l">
                <img src={DAWCode} alt="Pharm Resource -DAW Codes"></img>
                {/* <div style={{width: "132px", height: "170px", border: "solid 2px magenta"}}>
                    <h2 style={{color: "magenta"}}>FPO</h2>
                  </div> */}
              </div>
              <div className="flashCard-r1">Pharm Resource -DAW Codes</div>
              <div className="flashCard-r2">
                <a
                  target="_blank"
                  href=" https://amarincorp.com/docs/Pharm_DAW_Codes.pdf"
                  target="_blank"
                  className="cta-btn"
                >
                  Download
                </a>
              </div>
            </div>
          </div>

          
          <div className="item5">
            <div className="flashCard">
              <div className="flashCard-l">
                <img src={flashcardFour} alt="PA Letter - General"></img>
              </div>
              <div className="flashCard-r1">PA Letter - General</div>
              <div className="flashCard-r2">
                <a
                  href="https://amarincorp.com/docs/General_PA_Letter.pdf"
                  target="_blank"
                  className="cta-btn"
                >
                  Download
                </a>
              </div>
            </div>
          </div>
          <div className="item6">
            <div className="flashCard">
              <div className="flashCard-l">
                <img src={TexasPALetter} alt="PA Letter - Texas"></img>
              </div>
              <div className="flashCard-r1">PA Letter - Texas</div>
              <div className="flashCard-r2">
                <a
                  href="https://amarincorp.com/docs/Texas_PA_Letter.pdf"
                  target="_blank"
                  className="cta-btn"
                >
                  Download
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="dosing-callout"></div>
      </section>

      <hr />

      <section name="section4 mobile-m-b-20" className="endpoints">
        <h2 className="cv-headline">
          Questions about <strong>prior authorizations?</strong>
        </h2>
        <img
          data-modal="leavingsite-modal"
          data-href="https://www.covermymeds.com/main/?gclid=Cj0KCQiA7OnxBRCNARIsAIW53B_Q7gE0GUaSbPhWb4OyR0MaVmuQHrEOXSXD7mcEjwc2DaZGUDDAcw4aAqd7EALw_wcB"
          onClick={goModalTwo}
          className="chart-sm covermymeds-image-link"
          src={logo_cmm}
          alt="Covermymeds logo"
        />
        <button
          data-modal="leavingsite-modal"
          data-href="https://www.covermymeds.com/main/?gclid=Cj0KCQiA7OnxBRCNARIsAIW53B_Q7gE0GUaSbPhWb4OyR0MaVmuQHrEOXSXD7mcEjwc2DaZGUDDAcw4aAqd7EALw_wcB"
          onClick={goModalTwo}
          className="homeBtn mobile-align-center"
        >
          Learn more
        </button>

        <div className="dosing-callout"></div>
      </section>
    </main>
  )
}
