import React, { Component } from "react"
import HeroImage from '../components/3.3-access-coverage/HeroImage'

import Main from '../components/3.3-access-coverage/Main'
import Header from "../components/header/Header"
import ISI from '../components/isi/ISI'
import Footer from '../components/footer/Footer'
import Modal from '../components/modal/Modal'
import HomeModal from '../components/modal/HomeModal'
import SEO from "../components/seo"

import Loader from "../components/loader"


export default class AACoverage extends Component {

  constructor(props) {
    super(props)
    this.state = {
       stickyNav: true,
       isMobile: false,
       modal:  null,
       href : null,
       footnotes: [0,1,2]
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
    this.setState({ isMobile: bool })
  }

  handleClick(e) {
    // console.log('clicked')
  }

  goModal = (str,href) => {
    // console.log('clicked')
    // let str = evt.target.dataset.modal;
    this.setState(() => ({
      modal: str,
      href: href}))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null }))
  }




  render() {
    return (
      <>
        <Loader>
          <SEO
            title="VASCEPA Access and Coverage"
            keywords={[""]}
            description="Generic prescriptions may be costing your patients more than you think, while VASCEPA has unrestricted access on most plans regardless of TG level.* Access downloadable resources here. *TG levels ranging from 135-2000 mg/dL."
          />
          <HomeModal />
          <Modal
            modal={this.state.modal}
            href={this.state.href}
            modalClosed={this.modalClosed}
          />
          <Header
            isMobile={this.state.isMobile}
            hideNav={this.state.hideNav}
            goModal={this.goModal}
          />
          <div className="grad"></div>
          <HeroImage onClick={this.handleClick} bckgrdImage={"header3-3"} />
          <div className="wrapper">
            <Main
              isMobile={this.state.isMobile}
              goModalTwo={this.goModal}
              goModal={this.goModal}
            />
          </div>
          <ISI disclamer="Capsule is not actual size." />
          <div className="ref-outer">
            <div className="footnotes-container access-footer">
              <div className="footnotes">
                <p className="cv-footnote">
                  DAW=dispense as written; PA=prior authorization; PBMs=pharmacy
                  benefit managers.
                </p>
                <p className="cv-footnote hang">
                  <span>*</span>Data based on analysis of DRG formulary data and
                  Symphony claims data of patients' final out-of-pocket cost,
                  with eligible commercially insured patients using the VASCEPA
                  Savings Card. Data on file.
                </p>
                <p className="cv-footnote hang">
                  <sup>&dagger;</sup>Data on file.
                </p>
                <p className="cv-footnote hang">
                  <sup>&Dagger;</sup>As of October 26, 2022.
                </p>
                <p className="cv-footnote ">
                  <strong>Reference: 1.</strong> Kaiser Family Foundation.
                  Employer health benefits: 2021 annual survey.
                  https://files.kff.org/attachment/Report-Employer-Health-Benefits-2021-Annual-Survey.pdf.
                  Accessed February 4, 2022.
                </p>
              </div>
            </div>
            <div className="footnotes-container"></div>
          </div>
          <Footer />
        </Loader>
      </>
    )
  }
}
